var wW = $(window).width();
var breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}
function ajaxSubmitForm(form, url, doneCallback, failCallback) {
  form.find('.error').removeClass('error').next('.error-text').html('').hide();
  $.ajax({
    url: url,
    method: 'post',
    data: form.serialize()
  }).fail(function (data) {
    var response = JSON.parse(data.responseText);
    for (var key in response) {
      form.find('[name="' + key + '"]').addClass('error').next('.error-text').html(response[key]).show();
    }

    if (failCallback) {
      failCallback();
    }
  }).done(function (data) {
    doneCallback(data);
  });
}

$(function () {
  var mainSLider,
      autoplayDelay = 8,
      autoplayOnPause = false,
      autoplayTimer,
      currentPercent = 0,
      progressBar;
  
  function autoplayIterator() {
    if(autoplayOnPause === false) {
      currentPercent += 1 / (autoplayDelay + 0.1);
      /* progressBar.css({
        width: currentPercent + '%'
      }); */
      if(currentPercent >= 100) {
        mainSLider.slick('slickNext');
      }
    }
  }

  function autoplayInit(event, slick) {
    progressBar = slick.$dots.find('.dot').eq(0).find('.timeline-tick');
    autoplayTimer = setInterval(autoplayIterator, 10);
  }

  $('#main_slider_pager, .main-slider').on('mouseenter', function () {
    autoplayOnPause = true;
  })
  .on('mouseleave', function () {
    autoplayOnPause = false;
  });

  $('.main-slider').on('init', autoplayInit)
    .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      slick.$dots.find('.dot').eq(currentSlide).find('.timeline-tick').css('width', 0);
      progressBar = slick.$dots.find('.dot').eq(nextSlide).find('.timeline-tick');
      currentPercent = 0;
    })
    .on('afterChange', function (event, slick, currentSlide) {
      var num = currentSlide + 1;
      if(num < 10) {
        num = "0" + num;
      }
      $('#main_slider_number').text(num);
    });

  mainSLider = $('.main-slider').slick({
    dots: true,
    prevArrow: '<button type="button" class="slider-bt slider-bt--prev"><svg><use xlink:href="/img/sprite.svg#icon-arrow"></use></svg></button>',
    nextArrow: '<button type="button" class="slider-bt slider-bt--next"><svg><use xlink:href="/img/sprite.svg#icon-arrow"></use></svg></button>',
    appendDots: '#main_slider_pager',
    appendArrows: '#main_slider_arrows'
    /* customPaging: function (slick, i) {
      var num = i + 1;
      if(i < 10) {
        num = "0"+num;
      }
      return $('<button>', {
        text: num,
        type: "button",
        "class": 'dot',
        append: $('<span>', {
          "class" : 'timeline',
          append: $('<span>', {
            "class": 'timeline-tick'
          })
        })
      });
    } */
  });
  
  
  $('.partners-slider').slick({
    prevArrow: '<button type="button" class="slider-bt slider-bt--prev"><svg><use xlink:href="/img/sprite.svg#icon-arrow"></use></svg></button>',
    nextArrow: '<button type="button" class="slider-bt slider-bt--next"><svg><use xlink:href="/img/sprite.svg#icon-arrow"></use></svg></button>',
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });

  

  $('.accordion').rkAccordion({
    startIndex: 1,
    onAfterChange: function (accordion) {
      accordion.find('.accordion__item-caption').each(function () {
        var captionTextElement = $(this).find('.accorion__caption-toogled');
        if ($(this).hasClass('accordion__item-caption--active')) {
          captionTextElement.html('Свернуть');
        } else {
          captionTextElement.html('Подробнее');
        }
      });
    }
  });


  $(document).on('submit', '.review-form, .order-form, .callback-form', function (e) {
    e.preventDefault();
    var form = $(this);
    ajaxSubmitForm(form, '/netcat/add.php?isNaked=1', function (data) {
      var response = JSON.parse(data);
      form[0].reset();
      $.fancybox.close();
      $.fancybox.open('<div class="pp-message"><span>' + response.message + '</span></div>', { baseClass: 'fancy-form'});
    });
  });
  setInterval(function () {
    $('input[name="intr"]').each(function (i) {
      $(this).val(parseInt($(this).val()) + 3);
    });
  }, 3000);

  $('.menu-toggle').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('menu-toggle--open');
    $('.footer-menu').toggleClass('footer-menu--open');
    $('html, body').toggleClass('menu-open');
  });
  $('.footer-menu__title').click(function (e) {
    e.preventDefault();
    if(wW <= breakpoints.sm) {
      $(this).toggleClass('footer-menu__title--open')
    }
  });

  $('.events-slider').slick({
    prevArrow: '<button type="button" class="slider-bt slider-bt--prev"><svg><use xlink:href="/img/sprite.svg#icon-arrow"></use></svg></button>',
    nextArrow: '<button type="button" class="slider-bt slider-bt--next"><svg><use xlink:href="/img/sprite.svg#icon-arrow"></use></svg></button>',
    slidesToShow: 3,
    centerMode: true,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          initialSlide: 0
        }
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          initialSlide: 0,
          slidesToShow: 1
        }
      }
    ]
  });

  $('.year-selector select').change(function (e) {
    $(this).parent('form').submit();
  })
  /* if(wW < breakpoints.md) {
    $('.comission-structure__directors').slick({
      arrows: false,
      dots: false
    });
  } */

});

$(window).on('load', function () {
  $('.news-slider').slick({
    prevArrow: '<button type="button" class="slider-bt slider-bt--prev"><svg><use xlink:href="/img/sprite.svg#icon-arrow"></use></svg></button>',
    nextArrow: '<button type="button" class="slider-bt slider-bt--next"><svg><use xlink:href="/img/sprite.svg#icon-arrow"></use></svg></button>',
    slidesToShow: 4,
    slidesToScroll: 2,
    vertical: true,
    infinite: false,
    adaptiveHeight: true
  });
  $('.advice-main-slider').slick({
    prevArrow: '<button type="button" class="slider-bt slider-bt--prev"><svg><use xlink:href="/img/sprite.svg#icon-arrow"></use></svg></button>',
    nextArrow: '<button type="button" class="slider-bt slider-bt--next"><svg><use xlink:href="/img/sprite.svg#icon-arrow"></use></svg></button>',
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: breakpoints.md + 5,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: breakpoints.sm + 5,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
})